export default class PayRollYearStatus {
  parse(data) {
    this.aprilSigned = data.aprilSigned;
    this.augustSigned = data.augustSigned;
    this.decemberSigned = data.decemberSigned;
    this.employeeCode = data.employeeCode;
    this.employeeName = data.employeeName;
    this.februarySigned = data.februarySigned;
    this.januarySigned = data.januarySigned;
    this.julySigned = data.julySigned;
    this.juneSigned = data.juneSigned;
    this.marchSigned = data.marchSigned;
    this.maySigned = data.maySigned;
    this.novemberSigned = data.novemberSigned;
    this.octoberSigned = data.octoberSigned;
    this.septemberSigned = data.septemberSigned;
    this.year = data.year;

    return this;
  }
}
